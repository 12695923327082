
import { Component, Vue } from "vue-property-decorator";
import { Action, Mutation, Getter } from "vuex-class";
import { ISelectItem } from "@/types";
import { IMaker, IMakerListRequest } from "@/types/maker";
import { ISupplierListRequest } from "@/types/supplier";
import {
  IProduct,
  IProductCreateRequest,
  IProductRequest,
  IProductUpdateRequest
} from "@/types/product";
import { ProductRequest } from "@/models/product";

@Component({})
export default class extends Vue {
  //product
  @Action("product/adminGet")
  public getProduct!: (product_id: number) => void;

  @Action("product/adminCreate")
  public create!: (params: IProductCreateRequest) => Promise<boolean>;

  @Action("product/adminUpdate")
  public update!: (data: {
    product_id: number;
    params: IProductUpdateRequest;
  }) => Promise<boolean>;

  @Action("product/adminDelete")
  public deleteProduct!: (product_id: number) => Promise<boolean>;

  @Action("product/adminIsUniqueName")
  public isUniqueName!: (params: IProductRequest) => Promise<boolean>;

  @Action("product/adminIsUniqueCode")
  public isUniqueCode!: (params: IProductRequest) => Promise<boolean>;

  @Getter("product/single")
  public product!: IProduct;

  @Mutation("product/clear")
  public clear!: () => void;

  //maker
  @Action("maker/adminGetList")
  public getMakers!: (params: IMakerListRequest) => Promise<boolean>;

  @Getter("maker/selectItem")
  public makerList!: ISelectItem[];

  @Getter("maker/single")
  public maker!: IMaker;

  @Getter("maker/find")
  public findMaker!: (id: number) => IMaker;

  @Mutation("maker/clear")
  public clearMaker!: () => void;

  //supplier
  @Action("supplier/adminGetList")
  public getSuppliers!: (params: ISupplierListRequest) => Promise<boolean>;

  @Getter("supplier/selectItem")
  public supplierList!: ISelectItem[];

  @Mutation("supplier/clear")
  public clearSupplier!: () => void;

  //パラメータ定義
  public params: IProductRequest = new ProductRequest();

  //変数定義
  public product_id = 0;
  public submit_dialog = false;
  public destroy_dialog = false;
  public valid = true;
  public lazy = false;
  public name_valid = false;
  public code_valid = false;
  public is_focus = false;
  public name_errors: string[] = [];
  public code_errors: string[] = [];
  public name_code_errors: string[] = [];
  // 受発注中かどうか
  public ordering = 0;

  //ルール定義
  public rules = {
    name: [(v: string) => !!v || "商品名は必須です"],
    unit: [(v: string) => !!v || "単位は必須です"],
    unit_price: [
      (v: string) =>
        !v || /^([1-9]\d*|0)(\.\d+)?$/.test(v) || "半角数字で入力してください",
      (v: number) => v >= 0 || "0未満は入力できません",
      (v: string) =>
        !v ||
        /^([1-9]\d*|0)(\.\d{1,2})?$/.test(v) ||
        "小数点以下2桁までで入力してください"
    ],
    alert_quantity: [
      (v: string) =>
        !v || /^([1-9]\d*|0)(\.\d+)?$/.test(v) || "半角数字で入力してください",
      (v: number) => v >= 0 || "0未満は入力できません"
    ],
    maker_id: [(v: number) => !!v || "メーカは必須です"]
  };

  //--------
  // コンポーネント作成時実行
  public async created() {
    this.params.maker_id = Number(this.$route.params.maker_id);
    this.product_id = Number(this.$route.params.product_id);
    this.clear();

    // 編集時
    if (this.product_id) {
      await this.getProduct(this.product_id);
      this.setDefault();
      this.clearMaker();
      this.clearSupplier();
      this.ordering =
        this.params.orders_count + this.params.received_orders_count;
    }

    await Promise.all([
      this.getMakers({ per_page: 0 }),
      this.getSuppliers({ per_page: 0 })
    ]);

    this.$nextTick(function () {
      (this.$refs.form as Vue & { validate: () => boolean }).validate();
    });
  }

  //--------
  // 更新時デフォルト値をセットする
  private setDefault() {
    this.params.createFromProduct(this.product);
  }

  // 登録確認画面
  public submitConfirm() {
    this.submit_dialog = true;
  }

  //--------
  // 確定ボタンが押された際の挙動
  // 更新時と作成時で問合せ先が違う
  public async submit() {
    this.submit_dialog = false;
    let result = false;
    if (this.product_id) {
      result = await this.update({
        product_id: this.product_id,
        params: this.params
      });
    } else {
      result = await this.create(this.params);
    }
    if (result) {
      this.$router.go(-1);
    }
  }

  //--------
  // 削除確認画面
  public destroyConfirm() {
    this.destroy_dialog = true;
  }

  //--------
  // 削除実行
  public async destroy() {
    this.destroy_dialog = false;
    if (await this.deleteProduct(this.product_id)) {
      this.$router.push(`/admin/product/list`);
    }
  }

  // 二重登録チェック
  //商品名
  public async name_validate() {
    // 登録時
    if (!(await this.isUniqueName(this.params)) && !this.product.name) {
      this.name_valid = true;
      if (this.name_valid == true && this.code_valid == true) {
        this.name_code_validate();
      }
      this.is_focus = false;
      return (this.name_errors = ["既に使用されています"]);
    }

    // 編集時
    if (
      !(await this.isUniqueName(this.params)) &&
      this.product.name != this.params.name
    ) {
      this.name_valid = true;
      if (this.name_valid == true && this.code_valid == true) {
        this.name_code_validate();
      }
      this.is_focus = false;
      return (this.name_errors = ["既に使用されています"]);
    }

    this.name_valid = false;
    this.is_focus = false;
    return (this.name_errors = []), (this.name_code_errors = []);
  }

  //品番
  public async code_validate() {
    // 登録時
    if (!(await this.isUniqueCode(this.params)) && !this.product.code) {
      this.code_valid = true;
      if (this.name_valid == true && this.code_valid == true) {
        this.name_code_validate();
      }
      this.is_focus = false;
      return (this.code_errors = ["既に使用されています"]);
    }

    // 編集時
    if (
      !(await this.isUniqueCode(this.params)) &&
      this.product.code != this.params.code
    ) {
      this.code_valid = true;
      if (this.name_valid == true && this.code_valid == true) {
        this.name_code_validate();
      }
      this.is_focus = false;
      return (this.code_errors = ["既に使用されています"]);
    }

    this.code_valid = false;
    this.is_focus = false;
    return (this.code_errors = []), (this.name_code_errors = []);
  }

  //商品名と品番どちらも使用されているかチェック
  public async name_code_validate() {
    this.is_focus = false;
    return (this.name_code_errors = [
      "商品名、品番のどちらも既に使用されています"
    ]);
  }
}
